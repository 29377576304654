import Vue from 'vue'

import '../sass/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { LayoutPlugin, NavbarPlugin, ButtonPlugin, CarouselPlugin, CardPlugin, MediaPlugin, ListGroupPlugin, ModalPlugin} from 'bootstrap-vue'

Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(CarouselPlugin)
Vue.use(CardPlugin)
Vue.use(MediaPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
