<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Fogkőeltávolítás
        </p>
        <p class="tajekoztato-text">
          A húsevők szájhigiéniai problémái közül a fogkőképződés jelenti a leggyakoribb gondot. A fogak alapjánál fokozatosan felhalmozódó fogkőben rengeteg baktérium él, elősegíti lepedékképződést, és fájdalmas ínygyulladás, bűzös lehelet kiváltója lehet, valamint egyes szívbetegségek is összefüggésbe hozhatók vele.
        </p>
        <p class="tajekoztato-text">
          Eltávolítása bódításban, ultrahangos fogkő-eltávolítóval történik, egyúttal ellenőrizzük a fogak állapotát is
        </p>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "AltalanosOlt"
}
</script>

<style lang="scss" scoped>
@import "../sass/settings";
.card{
    border-radius: 0;
    border: none;
    padding: 60px 40px;
    .card-body{
        margin: 0;
        padding: 0;
    }
    ul{
        margin: 24px 0 8px;
        list-style: none;
        li{
            margin: 0;
            &::before{
                content: "\2022";
                color: $primary;
                font-weight: bolder;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@media screen and (max-width: 765px){
    main{
        background-color: #fff;
        .main-title{
            margin-left: 20px;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100%;
        &>.card{
            width: calc(100vw - 20px);
            margin: 0;
            padding: 20px;
        }
    }
}
</style>
