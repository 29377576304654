import Vue from 'vue'

//Fontawesome Vue components
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library, config} from '@fortawesome/fontawesome-svg-core'

import {
  faPaw,
  faSignOutAlt,
  faBars
} from '@fortawesome/free-solid-svg-icons'

//Load css by hand
import '@fortawesome/fontawesome-svg-core/styles.css'

config.autoAddCss = false

library.add(
  faPaw,
  faSignOutAlt,
  faBars
)

Vue.component('fa-icon', FontAwesomeIcon)
