<template>
  <main>
    <b-container>
      <div class="d-flex flex-wrap">
        <img
          v-b-modal.modal-one
          src="../../assets/images/galeria/rendelo_1.jpg"
          alt=""
        >
        <b-modal
          id="modal-one"
          size="xl"
          centered
          hide-header
          hide-footer
        >
          <img
            src="../../assets/images/galeria/rendelo_1.jpg"
            alt=""
          >
        </b-modal>

        <img
          v-b-modal.modal-two
          src="../../assets/images/galeria/rendelo_2.jpg"
          alt=""
        >
        <b-modal
          id="modal-two"
          size="xl"
          centered
          hide-header
          hide-footer
        >
          <img
            src="../../assets/images/galeria/rendelo_2.jpg"
            alt=""
          >
        </b-modal>

        <img
          v-b-modal.modal-three
          src="../../assets/images/galeria/rendelo_3.jpg"
          alt=""
        >
        <b-modal
          id="modal-three"
          size="xl"
          centered
          hide-header
          hide-footer
        >
          <img
            src="../../assets/images/galeria/rendelo_3.jpg"
            alt=""
          >
        </b-modal>

        <img
          v-b-modal.modal-four
          src="../../assets/images/galeria/rendelo_4.jpg"
          alt=""
        >
        <b-modal
          id="modal-four"
          size="xl"
          centered
          hide-header
          hide-footer
        >
          <img
            src="../../assets/images/galeria/rendelo_4.jpg"
            alt=""
          >
        </b-modal>

        <img
          v-b-modal.modal-five
          src="../../assets/images/galeria/rendelo_5.jpg"
          alt=""
        >
        <b-modal
          id="modal-five"
          size="xl"
          centered
          hide-header
          hide-footer
        >
          <img
            src="../../assets/images/galeria/rendelo_5.jpg"
            alt=""
          >
        </b-modal>
      </div>
    </b-container>
  </main>
</template>

<script>
export default {
    name: 'RendeloKepek'
}
</script>

<style lang="scss" scoped>
@import '../../sass/settings';
.d-flex{
    justify-content: center;
}
img{
    max-height: 250px;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.14);
    margin: 10px;
    cursor: pointer;
    max-width: 100vw;
}
@media screen and (max-width: 765px){
    main{
        padding: 0;
    }
    .container{
        margin: 0;
        padding: 0;
    }
    .d-flex{
        flex-direction: column;
    }
    img{
        max-width: calc(100vw - 20px);
        max-height: 100%;
        margin: 0 auto;
        &:not(:first-of-type){
            margin-top: 20px;
        }
    }
}
</style>

<style lang="scss">
.modal-dialog-centered{
    width: fit-content;
    padding: 0;
    max-width: 100vw !important;
    margin: 0 auto;
}
.modal-content{
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: fit-content;
    .modal-body{
        padding: 0;
    }
    img{
        max-height: calc(100vh - 80px);
        max-width:  100vw;
        margin: 0;
        cursor: auto;
    }
}
</style>

