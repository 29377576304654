import Vue from 'vue'

Vue.directive('mail-to', {
  bind (el, { value = 'info@vizavet.hu' }) {
    el.addEventListener('click', function (e) {
      e.preventDefault()
      window.open(`mailto:${value}`)
    });
  }
})
