<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Tájékoztató kutyák és macskák egyes fertőző megbetegedései ellen történő vakcinázásáról
        </p>
        <p class="tajekoztato-text">
          Magyarországon a jelenleg elérhető és társállatokon alkalmazott védőoltások három csoportba sorolhatóak:
        </p>

        <p class="tajekoztato-subtitle">
          1. Jogszabály által előírt kötelező oltás:
        </p>
        <p class="tajekoztato-text">
          A 164/2008. FVM rendelet (a veszettség elleni védekezés részletes szabályairól) alapján az állattartó köteles:
        </p>
        <ul>
          <li class="tajekoztato-text">
            Minden három hónaposnál idősebb ebet veszettség ellen saját költségén az állat állat-egészségügyi felügyeletét ellátó magánállatorvossal beoltatni az alábbiak szerint:
          </li>
          <li class="tajekoztato-text">
            a három hónapos kort elérteket 30 napon belül,
          </li>
          <li class="tajekoztato-text">
            az első oltást követően 6 hónapon belül,
          </li>
          <li class="tajekoztato-text">
            ezt követően évenként.
          </li>
        </ul>

        <p class="tajekoztato-subtitle">
          2. Feltétlenül ajánlott oltások:
        </p>
        <p class="tajekoztato-text">
          Mindenképpen javasolt az aerogén úton vagy bélsárral terjedő, környezetben ellenálló kórokozók okozta súlyos megbetegedések elleni védekezés.
        </p>
        <ul>
          <li class="tajekoztato-text">
            Kutyák esetében: parvovírus okozta bélgyulladás, szopornyica és a kutyák 1-es típusú adenovírus okozta fertőző májgyulladása (Rubarth-kór) ellen.
          </li>
          <li class="tajekoztato-text">
            Macskák esetében: parvovírus okozta panleukopenia, a macskák 1-es típusú herpesvírus okozta fertőző rhinotracheitise és a macska calicivírus okozta megbetegedése ellen.
          </li>
        </ul>

        <p class="tajekoztato-subtitle">
          3. Bizonyos esetekben javasolt oltások:
        </p>
        <p class="tajekoztato-text">
          Minden olyan fertőző megbetegedés elleni védőoltás sorolható ide, amelyek nem tartoznak az előző két pontban felsoroltakhoz.
        </p>
        <p class="tajekoztato-text">
          Alkalmazásuk egyedi elbírálás alá tartozik a fertőződés lehetősége (pl. kijáró macska, több befogadott macska együttes tartása, fertőzött területeken élő állatok, kennelben tartott állatok, versenyeken/kiállításokon/vadászatokon rendszeresen részt vevő állatok), illetve a védőoltás kockázat-haszon elemzésének alapján.
        </p>
        <ul>
          <li class="tajekoztato-text">
            Kutyák esetében: parainfluenza vírusok és Bordetella bronchiseptica okozta kennelköhögés, leptospirosis, Lyme-borreliosis, babesiosis.
          </li>
          <li class="tajekoztato-text">
            Macskák esetében: veszettség, leukosis és chlamydiosis elleni védőoltás.
          </li>
        </ul>
        <p class="tajekoztato-higlight1">
          Fontos információk!
        </p>
        <ul>
          <li class="tajekoztato-text">
            Védőoltásban csak egészséges (megbetegedés klinikai tüneteit nem mutató) egyed részesülhet.
          </li>
          <li class="tajekoztato-text">
            A fiatal, oltatlan állatokat a kölyökkori fertőző megbetegedések elleni vakcinázási protokoll befejezéséig izoláltan kell tartani.
          </li>
          <li class="tajekoztato-text">
            Macskáknál a leukosis elleni oltóanyag beadása előtt javasolt a fertőzöttség kimutatására szolgáló ún. FeLV-tesztet elvégeztetni.
          </li>
          <li class="tajekoztato-text">
            Az élő, gyengített kórokozókat tartalmazó oltóanyagokat a bőr fertőtlenítése nélkül kell befecskendezni.
          </li>
        </ul>
        <p class="tajekoztato-text">
          A védőoltás, mint megelőző célú beavatkozás sem mentes a kockázattól. Az előírásoknak megfelelően alkalmazott védőoltással összefüggésben nemkívánatos hatások, oltási reakciók, szövődmények jelentkezhetnek.
        </p>
        <p class="tajekoztato-text">
          Az oltási reakciók általános tünetek formájában (pl. hőemelkedés/láz, bágyadtság, hasmenés) vagy helyi reakcióként (injekció beadás helyén érzékenység, bőrpír, múló duzzanat) nyilvánulhatnak meg. Ezek az elváltozások általában csak enyhék és átmeneti jellegűek, állatorvosi beavatkozást nem igényelnek.
        </p>
        <p class="tajekoztato-text">
          Az oltási szövődmények nem szükségszerű velejárói a védőoltásoknak, hanem az immunizált állat átlagostól eltérő reakciókészségének következményei. Mivel a hajlamosító tényezők még nem ismertek teljes részletességükben, ezért az oltási szövődmények biztonsággal nem kerülhetőek el. Az oltási szövődmény lehet pl. allergiás reakció (viszkető kiütések megjelenése, pofa és szemhéjak beduzzadása, légzési nehézség), injekció beadása helyén kialakuló lágyszöveti daganatos folyamat, immun-mediált megbetegedés (vérszegénység, vérlemezke-megfogyás, vesegyulladás).
        </p>
        <p class="tajekoztato-text">
          Ha az immunizációt követően állatánál oltási szövődmény vagy annak gyanúja áll fenn, azonnal forduljon állatorvoshoz!
        </p>
        <p class="tajekoztato-text">
          Ha korábbi oltások alkalmával már jelentkezett az állatnál oltási reakció vagy szövődmény, úgy az oltóanyag beadása előtt kérjük tájékoztassa erről az állatorvost!
        </p>
        <p class="tajekoztato-text">
          A fentiekkel kapcsolatos részletesebb tájékoztatásért forduljon bizalommal állatorvosainkhoz!
        </p>
        <p class="tajekoztato-higlight2">
          Forrás: Belgyógyászati Tanszék és Klinika
        </p>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "AltalanosOlt"
}
</script>

<style lang="scss" scoped>
@import "../../sass/settings";
.card{
    border-radius: 0;
    border: none;
    padding: 60px 40px;
    .card-body{
        margin: 0;
        padding: 0;
    }
    ul{
        margin: 24px 0 8px;
        list-style: none;
        li{
            margin: 0;
            &::before{
                content: "\2022";
                color: $primary;
                font-weight: bolder;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@media screen and (max-width: 765px){
    main{
        background-color: #fff;
        .main-title{
            margin-left: 20px;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100%;
        &>.card{
            width: calc(100vw - 20px);
            margin: 0;
            padding: 20px;
        }
    }
}
</style>
