<template>
  <div id="app">
    <app-header />
    <app-nav />
    <router-view />
    <app-footer />
    <div class="copyright">
      <a
        href="http://webgarden.eu"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="./assets/images/WG_logo.png"
          alt="WebGarden webdesign"
        >
      </a>
    </div>
  </div>
</template>
<script>
import AppFooter from './components/footer'
import AppHeader from './components/header'
import AppNav from './components/nav'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppNav,
    AppFooter
  }
}
</script>
<style lang="scss">
@import 'sass/settings';

main {
  background-color: $background;
  padding: 30px 0 60px;
  min-height: calc(100vh - 220px - 161px - 68px);
}

.copyright {
  padding: 15px 0;
  width: 100%;
  text-align: center;
  background-color: #888;

  img {
    height: 38px;
  }
}
</style>
