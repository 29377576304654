<template>
  <b-navbar
    toggleable="md"
    type="light"
    class="p-0 justify-content-end"
  >
    <b-button
      v-b-toggle.nav-collapse
      class="hamb d-block d-md-none"
    >
      <fa-icon icon="bars" />
    </b-button>
    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav class="mx-auto">
        <b-nav-item
          :to="{name: 'rolunk'}"
          class="menu-font mr-0 mr-md-2"
        >
          <fa-icon
            icon="paw"
            class="mr-1 d-none d-md-inline"
            fixed-width
          />
          <span>Rólunk</span>
        </b-nav-item>
        <b-nav-item
          :to="{name: 'szolgaltatasok'}"
          class="menu-font mr-0 mr-md-2"
        >
          <fa-icon
            icon="paw"
            class="mr-1 d-none d-md-inline"
            fixed-width
          />
          <span>Szolgáltatások</span>
        </b-nav-item>
        <b-nav-item
          :to="{name: 'arlista'}"
          class="menu-font mr-0 mr-md-2"
        >
          <fa-icon
            icon="paw"
            class="mr-1 d-none d-md-inline"
            fixed-width
          />
          <span>Árak</span>
        </b-nav-item>
        <b-nav-item-dropdown class="menu-font mr-0 mr-md-2">
          <template slot="button-content">
            <fa-icon
              icon="paw"
              class="mr-1 d-none d-md-inline"
              fixed-width
            />
            <span>Tájékoztatók</span>
          </template>
          <b-dropdown-item
            :to="{name: 'oltasok'}"
            class="submenu-font"
          >
            Oltások
          </b-dropdown-item>
          <hr>
          <b-dropdown-item
            :to="{name: 'fereghajtas'}"
            class="submenu-font"
          >
            Féreghajtás
          </b-dropdown-item>
          <hr>
          <b-dropdown-item
            :to="{name: 'ivar'}"
            class="submenu-font"
          >
            Ivartalanítás
          </b-dropdown-item>
          <hr>
          <b-dropdown-item
            :to="{name: 'fogko'}"
            class="submenu-font"
          >
            Fogkőeltávolítás
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          :to="{name: 'kapcsolat'}"
          class="menu-font mr-0 mr-md-2"
        >
          <fa-icon
            icon="paw"
            class="mr-1 d-none d-md-inline"
            fixed-width
          />
          <span>Kapcsolat</span>
        </b-nav-item>

        <b-nav-item
          :to="{name: 'galeria'}"
          class="menu-font"
        >
          <fa-icon
            icon="paw"
            class="mr-1 d-none d-md-inline"
            fixed-width
          />
          <span>Galéria</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  export default {
    name: 'Nav',
    data() {
      return {
        open: false
      }
    },
    watch: {
      $route() {
        if (this.open) {
          this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
        }
      }
    },
    mounted() {
      this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        this.open = isJustShown
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import '../sass/main';

  button:focus {
    box-shadow: none !important;
  }

  .navbar {
    background-color: $secondary;

    svg {
      color: $primary;
    }
  }

  .hamb {
    min-height: 38px;
    border: none;
    border-radius: 0;

    svg {
      color: white;
    }
  }

  @include media-breakpoint-down(sm) {
    .nav-item,
    .nav-item-dropdown {
      border-top: 1px solid #444;
      text-align: center;
    }
  }
</style>

<style lang="scss">
  @import '../sass/settings';

  .dropdown-menu {
    border: none;
    margin: 0;
    border-radius: 0;
    background-color: $primary;
    padding: 0;

    li a:hover {
      background-color: #61a52e;
    }

    hr {
      width: 70%;
      margin: auto !important;
      display: block;
      border: #a9f470 solid 1px;
      padding: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .dropdown-item {
      padding: .3em 0;
      text-align: center;
      background-color: darken($secondary, 10%);
      color: $menu-font !important;

      &:hover {
        background-color: darken($secondary, 15%) !important;
      }
    }
    hr {
      border: 1px solid #444 !important;
      border-top: none !important;
      width: 100% !important;
    }
    .dropdown-menu {
      border-top: 1px solid #444 !important;
    }
  }
</style>

