<template>
  <main>
    <b-container>
      <p class="main-title">
        Kapcsolat
      </p>
      <b-card>
        <b-row>
          <b-col cols="4">
            <p class="kapcsolat-title">
              Nyitvatartás:
            </p>
            <p class="kapcsolat-text">
              Hétfő-csütörtök: 10:00-20:00
            </p>
            <p class="kapcsolat-text">
              Péntek: 10:00-19:00
            </p>
            <p class="kapcsolat-text">
              Szombat, vasárnap: Zárva
            </p>
            <p class="kapcsolat-text">
              Bejelentkezéshez minden esetben foglaljon időpontot!<br>
              Bejelentkezés nélküli vizsgálat vagy beavatkozás esetén időpont nélküli pótdíjat számolunk fel!
            </p>

            <p class="kapcsolat-title">
              Címünk:
            </p>
            <p class="kapcsolat-text">
              1138 Budapest, Bodor u. 3/C fsz 8-9.
            </p>

            <p class="kapcsolat-title">
              Telefon:
            </p>
            <p class="kapcsolat-text">
              <a href="tel:+3612291662">06 1 229-16-62</a>
            </p>
            <p class="kapcsolat-text">
              <a href="tel:+36303921495">06 30 392-14-95</a>
            </p>

            <p class="kapcsolat-title">
              Email:
            </p>
            <p class="kapcsolat-text">
              <a
                v-mail-to
                href="#"
              >
                <span class="email">@</span>
              </a>
            </p>
          </b-col>
          <b-col cols="8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2693.509356229461!2d19.06274041592578!3d47.538414500678414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dba609ef8059%3A0x5d605f19d093ff61!2sViza-Vet+%C3%81llatorvosi+Rendel%C5%91!5e0!3m2!1shu!2shu!4v1558307519120!5m2!1shu!2shu"
              width="100%"
              height="100%"
              frameborder="0"
              style="border:0"
              allowfullscreen
            />
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
  name: "Kapcsolat"
}
</script>

<style lang="scss" scoped>
@import "../sass/settings";

.card {
  border-radius: 0;
  border: none;
  padding: 0 0 0 40px;

  .card-body {
    margin: 0;
    padding: 0;
  }

  .col-4 {
    padding: 20px 0;
  }

  .kapcsolat-title:first-of-type {
    margin-top: 0;
  }
}

@media screen and (max-width: 765px) {
  main {
    background-color: #fff;

    .main-title {
      margin-left: 20px;
    }
  }
  .container {
    margin: 0;
    padding: 0;
    width: 100vw;
    max-width: 100%;

    & > .card {
      width: calc(100vw - 20px);
      margin: 0;
      padding: 40px;
    }
  }
  iframe {
    width: calc(100vw - 80px);
  }
}

@media screen and (max-width: 992px) {
  .row {
    flex-direction: column;

    .col-8 {
      height: 500px;
      padding: 0;
      padding-bottom: 20px;
      max-width: calc(100% - 40px);

      iframe {
        min-height: 500px;
      }
    }

    .col-4 {
      max-width: calc(100% - 40px);
    }
  }
}
</style>
