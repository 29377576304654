<template>
  <main>
    <b-container>
      <b-row>
        <b-col>
          <router-link :to="{name: 'rendelo'}">
            <b-card
              img-src="../assets/images/galeria/rendelo_icon.jpg"
              img-top
              class="text-center"
            >
              <b-card-title>
                Képek
              </b-card-title>
            </b-card>
          </router-link>
        </b-col>
        <b-col>
          <router-link :to="{name: 'videok'}">
            <b-card
              img-src="../assets/images/oltasok/oltasok_kutya.jpg"
              img-top
              class="text-center"
            >
              <b-card-title>
                Videók
              </b-card-title>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
 <script>
 export default {
     name: "Oltasok"
 }
 </script>

 <style lang="scss" scoped>
 @import '../sass/settings';
.row{
    margin-top: 30px;
    justify-content: center;
    .col{
        flex-grow: 0;
        padding-bottom: 20px;
        a:hover{
            text-decoration: none;
        }
    }
}
.card{
    border-radius: 0;
    border: none;
    background-color: $primary;
    color: white;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.14);
    width: 220px;
    margin: 0 auto;
    .card-title{
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        margin-bottom: 0;
    }
    img{
        border-radius: 0;
    }
}
</style>
