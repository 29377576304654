<template>
  <main class="py-0">
    <b-carousel
      id="carousel-slide"
      style="text-shadow: 0 0 2px #000"
      indicators
      controls
      class="d-sm-block d-none"
    >
      <b-carousel-slide
        img-src="../assets/images/slider/slider_1.jpg"
      />
      <b-carousel-slide
        img-src="../assets/images/slider/slider_2.jpg"
      />
    </b-carousel>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="text-center border-0">
            <b-card-title class="home-left-text1">
              &#8222;Barátságos, ígényes környezetben várjuk a felelős állattartókat és kedvenceinket&#8221;
            </b-card-title>
            <figure>
              <img
                src="../assets/images/home/home_bal.jpg"
                alt=""
              >
              <figcaption>
                <p class="home-left-text2">
                  Dr. Sótonyi Kata
                </p>
                <p class="home-left-text3">
                  Állatorvos, a Viza-Vet Állatorvosi Rendelő tulajdonosa
                </p>
              </figcaption>
            </figure>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center border-0">
            <b-card-title class="home-title1">
              Állatpatika
            </b-card-title>
            <b-card-sub-title class="home-title2">
              (állatorvosi szaktanácsadáassal)
            </b-card-sub-title>
            <b-card-text class="home-right-text">
              Vényre felírt illetve vény nélküli gyógyszerek <br>
              Külső és belső élősködők elleni készítmények <br>
              Táplálékkiegészítők, vitaminok <br>
              Porcépítő készítmények <br>
              Gyógytápok (diétás táplálás kisállatoknál)
            </b-card-text>
            <img
              src="../assets/images/home/home_jobb.jpg"
              alt=""
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "Home"
}
</script>

<style lang="scss" scoped>
@import '../sass/main';
main{
    background-color: $background;
    .card{
        border-radius: 0;
        min-height: 760px;
        margin: 30px 0;
        padding-top: 20px;
        figure{
            width: 70%;
            margin: auto;
            p{
                margin-top: 20px;
            }
        }
        img{
            box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.14);
            width: 100%;
            stroke: #fff;
            stroke-width: 1;
        }
        .card-text{
            margin: 30px 0;
        }
    }
}
@media screen and (max-width: 992px){
    .card{
        min-height: 630px !important;
    }
    .container{
        max-width: 800px;
}
}
@media screen and (max-width: 765px){
    .row{
        flex-direction: column;
        margin: 0;
        .col{
            padding: 0;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        .card{
            margin: 0;
            width: 100%;
            padding: 30px 0;
            &:last-of-type{
                border-top: 1px solid #dedace;
            }
        }
    }
}
</style>
<style lang="scss">
.carousel-control-prev, .carousel-control-next{
    width: 8%
}
</style>
