<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Macska oltási program
        </p>
        <p class="tajekoztato-subtitle">
          Benti, lakásban tartott macskák részére:
        </p>
        <p class="tajekoztato-text">
          Minden macska számára ajánlott védőoltás azon vírusos betegségek ellen, melyek kórokozói igen ellenállóak, így ruhával, cipővel bárki bejuttathatja az állat élőhelyére, nem szükséges a fertőzés átadásához direkt érintkezés beteg állattal. Ide soroljuk a macskák fertőző gyomor- és bélgyulladásának (Parvovirus), macskanáthájának (Calicivirus), és fertőző orr- és légcsőgyulladásának (Herpesvirus) kórokozóit. Szerencsére e betegségek megelőzhetőek kombinált oltással.
        </p>

        <ul>
          <li class="tajekoztato-text">
            8-9. hetes korban: <span class="tajekoztato-higlight1">Kombinált oltás</span> (fertőző orr-légcsőgyulladás, fertőző gyomor- és bélgyulladás, calicivírus okozta nátha)
          </li>
          <li class="tajekoztato-text">
            11-12 hetes: <span class="tajekoztato-higlight1">Kombinált oltás</span>
          </li>
        </ul>

        <p class="tajekoztato-subtitle">
          Kijáró macskák részére:
        </p>
        <p class="tajekoztato-text">
          Veszélyeztetettség alapján az ajánlott <span class="tajekoztato-higlight2">alap kombinált oltás</span> (fertőző orr-légcsőgyulladás, fertőző gyomor- és bélgyulladás, calicivírus okozta nátha,) mellett egyéb védőoltások közé tartozik a <span class="tajekoztato-higlight2">macska leukémia</span> (leucosis – FeLV, Retrovirus), <span class="tajekoztato-higlight2">veszettség</span> (rabies, Rhabdovirus) vírusa, valamint a <span class="tajekoztato-higlight2">bőrgombásság</span> (dermatophitosis, dermatomycosis) és a fertőző kötőhártya gyulladás (Chlamydophila felis) kórokozója elleni védőoltások. A legnagyobb veszélynek a kijáró, kertben élő macskák vannak kitéve. A leucosis terjedésben a macskák közvetlen és tartós érintkezésének, együtt tartásának van döntő jelentősége. Gyakori az utcai marakodás során történő fertőződés, de egy vírushordozó anya a vemhesség alatt a magzatokat is megbetegítheti, így a megszülető kismacskák már fertőzötten jöhetnek a világra. Kijáró macskák esetében mindenkor ajánlott a macska leukémia (leucosis) elleni védőoltás. Sajnos ez a betegség nem gyógyítható, de vérvizsgálattal egyértelműen diagnosztizálható, ezért minden macskát javasolt a betegségre szűrni és az eredménytől függően vakcinázni!
        </p>

        <ul>
          <li class="tajekoztato-text">
            8-9. hetes korban: <span class="tajekoztato-higlight1">Nagy kombinált oltás</span> (fertőző orr-légcsőgyulladás, fertőző gyomor- és bélgyulladás, calicivírus okozta nátha, chlamydiosis, leucosis)
          </li>
          <li class="tajekoztato-text">
            11-12. hetes korban: <span class="tajekoztato-higlight1">Nagy kombinált ismétlő</span>
          </li>
          <li class="tajekoztato-text">
            13-15.hetes kor: <span class="tajekoztato-higlight1">Veszettség elleni oltás</span>
          </li>
          <li class="tajekoztato-text">
            15-17 hetes kor: <span class="tajekoztato-higlight1">Bőrgombásság elleni vakcina</span> (kutya és macska tenyészetekben elő- forduló dermatophytosis megelőzésére)
          </li>
          <li class="tajekoztato-text">
            17-19 hetes kor: <span class="tajekoztato-higlight1">Bőrgombásság elleni vakcina ismétlése</span>
          </li>
        </ul>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "AltalanosOlt"
}
</script>

<style lang="scss" scoped>
@import "../../sass/settings";
.card{
    border-radius: 0;
    border: none;
    padding: 60px 40px;
    .card-body{
        margin: 0;
        padding: 0;
    }
    ul{
        margin: 24px 0 8px;
        list-style: none;
        li{
            margin: 0;
            &::before{
                content: "\2022";
                color: $primary;
                font-weight: bolder;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@media screen and (max-width: 765px){
    main{
        background-color: #fff;
        .main-title{
            margin-left: 20px;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100%;
        &>.card{
            width: calc(100vw - 20px);
            margin: 0;
            padding: 20px;
        }
    }
}
</style>
