<template>
  <main>
    <b-container>
      <p class="main-title">
        Árlista
      </p>
      <b-card>
        <p class="ar-text">
          Áraink tájékoztató jellegűek, a gyógyszerköltség esetenként változhat.
        </p>
        <p class="ar-title">
          Általános
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_alt"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Kutya oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_kutya"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Macska oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_macska"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Vadászgörény oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_goreny"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Nyúl oltások
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_nyul"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Gyors tesztek
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_gyors_tesztek"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Infúziós kezelés
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_infuzio"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Kötés felhelyezése
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_kotes"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Altatás
        </p>
        <p class="ar-title small">
          Bódítás ébresztéssel
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_altatas_boditas"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small">
          Műtéti altatás
        </p>
        <p class="ar-desc">
          (vénakanül, infúzió, rizikó páciens esetén propofor+opiat, altatógép)
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_altatas_muteti"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title">
          Műtétek
        </p>
        <p class="ar-desc">
          Minimál invazív technika (lehető legkisebb vágási seb, felszívódó varrat, minden ivarszerv eltávolításával, a
          műtétek az altatás árát tartalmazzák).
        </p>
        <p class="ar-title small">
          Macska
        </p>
        <p class="ar-desc">
          (antibiotikummal, fájdalomcsillapítóval, műtét utáni ruhával)
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_macska"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small">
          Kutya
        </p>
        <p class="ar-desc">
          <strong>Kan, castratio</strong> (antibiotikummal fájdalomcsillapítóval, hányáscsillapítóval, műtét utáni
          ruhával, monitoros altatással)
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_kutya_kan"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small"/>
        <p class="ar-desc">
          <strong>Nőstény kutya ivartalanítás</strong> (antibiotikummal fájdalomcsillapítóval, műtét utáni ruhával,
          monitoros altatással)
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_kutya_szuka"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-title small"/>
        <p class="ar-desc">
          <strong>Fogkő eltávolítása ultrahanggal</strong> (bódítással, műszeres monitorozással, fájdalomcsillapítóval,
          hányáscsillapítóval)
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_kutya_fogko"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="ar-desc">&nbsp;</p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_felar"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <div class="ar-title small">Endoszkópos vizsgálat</div>
        <p class="ar-desc">(+altatás/bódítás költsége és gyógyszerköltség)</p>
        <b-list-group>
          <b-list-group-item
            v-for="(ar, idx) in arak_mutet_endoszkop"
            :key="idx"
          >
            <p class="ar-list-title">
              {{ ar.title }}
            </p>
            <p class="ar-list-price">
              {{ ar.price }}
            </p>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-container>
  </main>
</template>
<script>
export default {
  name: 'Arlista',
  data () {
    return {
      arak_alt: [
        { title: 'Vizsgálati díj (kutya, macska)', price: '12.500 Ft' },
        { title: 'Vizsgálati díj sürgősségi (bejelentkezés nélkül)', price: '15.000 Ft' },
        { title: 'Vizsgálati díj ultrahanggal (hasi)', price: '18.000 Ft' },
        { title: 'Ultrahangos vizsgálat (hasi, egy szerv)', price: '7.500 Ft' },
        { title: 'Ultrahangos vizsgálat (hasi, több szerv)', price: '10.000 Ft' },
        { title: 'Vizsgálati díj (egzotikus, fajtúl függően)', price: '6.000-8.000Ft' },
        { title: 'Kontroll vizsgálat (kutya, macska)', price: '6.500 Ft' },
        { title: 'Kontroll (egzotikus, fajtól függően)', price: '2.500-4.000 Ft' },
        { title: 'Utókezelés', price: '4.000 Ft' },
        { title: 'Műtét előtti /műtét utáni konzultáció', price: 'Ingyenes' },
        { title: 'Microchip beültetés + regisztráció', price: '16.000 Ft' },
        { title: 'Kisállat egészségügyi könyv', price: '2.000 Ft' },
        { title: 'Útlevél kiállítás', price: '18.000 Ft' },
        { title: 'Videotoszkópos fülvizsgálat', price: '8.000 Ft' },
        { title: 'Fültisztítás', price: '5.000 Ft' },
        { title: 'Fülszőr tépés', price: '4.500 Ft' },
        { title: 'Fülmosás', price: '3.500 Ft' },
        { title: 'Bűzmirigy toalett', price: '4.500 Ft' },
        { title: 'Karomvágás', price: '4.500 Ft' },
        { title: 'Hánytatás', price: '14.000 Ft' },
        { title: 'Fluorescein festés', price: '6.000 Ft' },
        { title: 'Schirmer teszt', price: '6.000 Ft' },
        { title: 'Vércukormérés', price: '4.500 Ft' },
        { title: 'Vizeletvizsgálat tesztcsíkkal', price: '4.000 Ft' },
        { title: 'Vérvétel', price: '4.500 Ft' },
        { title: 'Mintavétel', price: '4.000 Ft' },
        { title: 'Receptírás', price: '2.000 Ft' }
      ],
      arak_kutya: [
        { title: 'Veszettség', price: '12.500 Ft' },
        { title: 'Kombinált vakcina', price: '14.000 Ft' },
        { title: 'Veszettség + kombinált', price: '16.500 Ft' },
        { title: 'Kennel köhögés', price: '13.500 Ft' },
        { title: 'Lyme kór elleni', price: '13.500 Ft' },
        { title: 'Microsporiasis (gomba) ', price: '13.500 Ft' }
      ],
      arak_macska: [
        { title: 'Veszettség', price: '12.500 Ft' },
        { title: 'Kombinált', price: '14.000 Ft' },
        { title: 'Veszettség + kombinált', price: '16.500 Ft' },
        { title: 'Leukosis elleni', price: '13.000 Ft' },
        { title: 'Leukosis + kombinált', price: '16.500 Ft' },
        { title: 'Microsporiasis (gomba)', price: '13.500 Ft' }
      ],
      arak_goreny: [
        { title: 'Kombinált', price: '14.000 Ft' },
        { title: 'Veszettség + kombinált', price: '16.500 Ft' }
      ],
      arak_nyul: [
        { title: 'Pestorin Mormyx', price: '11.500 Ft' },
        { title: 'Nobivac Myxo-RHD PLUS', price: '16.500 Ft' }
      ],
      arak_gyors_tesztek: [
        { title: 'FIV/FeLV', price: '12.000 Ft' },
        { title: 'Giardia', price: '10.000 Ft' },
        { title: 'Vastagcsepp + szívférgesség', price: '12.500 Ft' },
        { title: 'Parvo/Corona/Giardia Uranotest gyorsteszt', price: '15.000 Ft' }
      ],
      arak_infuzio: [
        { title: 'Vénakanüllel', price: '12.500 Ft' },
        { title: '2. alkalomtól', price: '6.000 Ft' },
        { title: 'Subcutan', price: '4.000 Ft' }
      ],
      arak_kotes: [
        { title: 'Fedőkötés egyszerű', price: '4.000 Ft' },
        { title: 'Fedőkötés bonyolult', price: '6.000 Ft' }
      ],
      arak_altatas_boditas: [
        { title: 'kistestű 10 kg alatti', price: '10.000 Ft' },
        { title: 'közepes testű 10-25 kg', price: '12.000 Ft' },
        { title: 'nagy testű 25 feletti', price: '15.000 Ft' }
      ],
      arak_altatas_muteti: [
        { title: 'Kistestű 10kg alatti', price: '19.500 Ft' },
        { title: 'Közepes 10-25 kg', price: '24.500 Ft' },
        { title: 'Nagy testű 25kg feletti ', price: '28.000 Ft' }
      ],
      arak_mutet_macska: [
        { title: 'Nőstény ivartalanítása', price: '55.000 Ft-tól' },
        { title: 'Castratio ', price: '26.000 Ft-tól' }
      ],
      arak_mutet_kutya_kan: [
        { title: 'Kis testű 10kg-ig', price: '~70.000 Ft' },
        { title: 'Közepes testű 10-20 kg közötti', price: '~82.000 Ft' },
        { title: 'Nagy testű 20-40 kg közötti', price: '~99.800 Ft' }
      ],
      arak_mutet_kutya_szuka: [
        { title: 'Kistestű 10 kg-ig', price: '~88.000 Ft' },
        { title: 'Közepes testű 20 kg-ig', price: '~99.500 Ft' },
        { title: 'Nagy testű 40kg-ig ', price: '~120.000 Ft' }
      ],
      arak_mutet_felar: [
        { title: 'Pyometra felár', price: '8.000 Ft' },
        { title: 'Rejtett here felár (helyeződéstől függően) ', price: '6.000-18.000 Ft' },
        { title: 'Foghúzás, maradandó fog', price: '5.000 Ft' },
        { title: 'Foghúzás, tejfog', price: '2.500 Ft' },
        { title: 'Sebzárás varrással:', price: '15.000-25.000 Ft' },
        { title: 'Emlődaganat eltávolítás', price: '20.000Ft-38.000 Ft' },
        { title: 'Othaematoma műtét', price: '20.000-35.000 Ft' },
        { title: 'Bőrdaganat eltávolítása', price: '17.000-35.000 Ft' },
        { title: 'Sérvműtét (köldök, lágyék)', price: '18.000-35.000 Ft' },
        { title: 'Tályog megnyitás és drenálás', price: '8.500 Ft' }
      ],
      arak_mutet_endoszkop: [
        { title: 'Egy szerv esetén', price: '40.000 Ft' },
        { title: 'Több szerv esetén ', price: '60.000 Ft' }
      ]
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
@import '../sass/settings';

.card {
  border-radius: 0;
  border: none;
  padding: 60px 40px;

  .card-body {
    margin: 0;
    padding: 0;
  }

  .list-group {
    border: none;
    max-width: 860px;
    margin: auto;

    .list-group-item {
      border: none;
      display: flex;
      justify-content: space-between;
      border-radius: 0;

      &:nth-of-type(2n+1) {
        background: #f8f8f8;
      }

      &:hover {
        background-color: #dedace;
      }

      p {
        display: block;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  main {
    background-color: #fff;

    .main-title {
      margin-left: 20px;
    }
  }
  .container {
    margin: 0;
    padding: 0;
    width: 100vw;
    max-width: 100%;

    & > .card {
      width: calc(100vw - 20px);
      margin: 0;
      padding: 20px;
    }
  }
}
</style>
