<template>
  <main>
    <b-container>
      <div class="d-flex flex-wrap">
        <iframe
          src="https://www.youtube.com/embed/z5uUBzeo0XU"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </b-container>
  </main>
</template>

<script>
export default {
    name: 'RendeloKepek'
}
</script>

<style lang="scss" scoped>
@import '../../sass/settings';
.d-flex{
    justify-content: center;
    max-width: calc(100vw - 20px);
}
iframe{
    min-width: 50%;
    margin: 20px auto;
    min-height: 350px;
}
@media screen and (max-width: 765px){
    main{
        padding: 0;
    }
    .container{
        margin: 0;
        padding: 0;
    }
    .d-flex{
        max-width: calc(100vw - 20px);
    }
}
</style>
