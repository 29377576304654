<template>
  <main>
    <b-container>
      <p class="main-title">
        Szolgáltatásaink
      </p>

      <b-list-group>
        <b-list-group-item class="szolg-text">
          általános betegellátás, belgyógyászati betegvizsgálatok, kezelések
        </b-list-group-item>
        <b-list-group-item
          class="szolg-link"
          :to="{ name : 'oltasok'}"
        >
          oltási programok (kutya, macska, nyúl,görény)
        </b-list-group-item>
        <b-list-group-item
          class="szolg-link"
          :to="{ name : 'fereghajtas'}"
        >
          parazita ellenes kezelések
        </b-list-group-item>
        <b-list-group-item class="szolg-text">
          microchip beültetés, regisztrálás
        </b-list-group-item>
        <b-list-group-item class="szolg-text">
          állat útlevél kiállítás
        </b-list-group-item>
        <b-list-group-item class="szolg-text">
          szűrővizsgálatok, betegség megelőző vizsgálatok
        </b-list-group-item>
        <b-list-group-item class="szolg-text">
          laborvizsgálat (vérvizsgálat,vizeletvizsgálat, bélsárvizsgálat, bőrkaparék vizsgálat)
        </b-list-group-item>
        <b-list-group-item class="szolg-text">
          lágysebészet, általános műtéti ellátás
        </b-list-group-item>
        <b-list-group-item
          class="szolg-link"
          :to="{ name : 'ivar'}"
        >
          speciális ivartalanitas (nőstény macskáknál 1-2 cm, nőstény kutyánál kb. 2-3 cm-és vágási seben keresztül)
        </b-list-group-item>
        <b-list-group-item
          class="szolg-link"
          :to="{ name : 'fogko'}"
        >
          fogászati kezelések, ultrahangos fogkőeltávoltítás
        </b-list-group-item>
        <b-list-group-item class="szolg-text">
          állatpatika és petshop
        </b-list-group-item>
      </b-list-group>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "Szolgaltatasok"
}
</script>

<style lang="scss" scoped>
.list-group, .list-group-item{
    border-radius: 0;
    border: none;
}
.list-group-item{
    margin: 10px 0;
    text-align: center;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.14);
    padding: 25px 10px;
}
</style>
