<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Kutya oltási program
        </p>
        <ul>
          <li class="tajekoztato-text">
            6-8. hetes kor: <span class="tajekoztato-higlight1">Parvovírusos hasmenés elleni védőoltás</span>
          </li>
          <li class="tajekoztato-text">
            8-10 hetes kor: <span class="tajekoztato-higlight1">Kombinált oltás</span> (Parvovírusos hasmenés, Szopornyica, Fertőző májgyulladás, Leptospirosis, Coronavírusos hasmenés, parainfluenza)
          </li>
          <li class="tajekoztato-text">
            10-12 hetes kor: <span class="tajekoztato-higlight1">Kombinált ismétlő</span>
          </li>
          <li class="tajekoztato-text">
            3 hónapos korban (13-15 hetes kor): <span class="tajekoztato-higlight1">Veszettség elleni oltás</span>
          </li>
          <li class="tajekoztato-text">
            6. hónapos korban: <span class="tajekoztato-higlight1">Kombinált ismétlő</span>
          </li>
          <li class="tajekoztato-text">
            9  hónapos korban: <span class="tajekoztato-higlight1">Veszettség ismétlő</span>
          </li>
        </ul>

        <p class="tajekoztato-text">
          Ezt követően a kombinált oltást és a veszettség elleni oltást is évente kell ismételni.
        </p>
        <p class="tajekoztato-text">
          <span class="tajekoztato-higlight2">Az alapimmunizáláson felül ajánlott oltások</span>, egyedi elbírálás alapján:
        </p>

        <ul>
          <li class="tajekoztato-text">
            17-18 hetes korban: <span class="tajekoztato-higlight2">Lyme-kór elleni oltás</span>  (kullancs szempontjából veszélyeztetett területen élő kutyák számára ajánlott)
          </li>
          <li class="tajekoztato-text">
            19-21 hetes korban: <span class="tajekoztato-higlight2">Lyme-kór elleni oltás ismétlése</span>
          </li>
          <li class="tajekoztato-text">
            <span class="tajekoztato-higlight2">Bőrgombásság:</span> egyedi elbírálás alapján, függően a tartási körülményektől és a fertőzések előfordulásának gyakoriságától ajánjuk. Megelőzés és gyógykezelés céljából egyaránt alkalmazható 8 hetes életkortól.
          </li>
          <li class="tajekoztato-text">
            <span class="tajekoztato-higlight2">Kennel köhögés:</span> a kutyák rendkívüli fertőzőképességű légcső- és hörgőgyulladása, amely jellemzően csoportban tartott, kutyatársaságba járó kedvenceket érinti. Bár, ha szövődmények nem társulnak hozzá, nem különösen veszélyes, a beteg kutyák rohamszerű száraz köhögést produkálnak, a rohamok folyamatosan jelentkeznek, lehetetlenné téve ezáltal ember és állat pihenését egyaránt. Ha kutyája gyakran jár kutyaközösségbe, kutyaiskolába, kutyapanzióba, feltétlenül javasoljuk a védekezést e betegség ellen. A vakcina nem injekció, hanem orrba csepegtetendő oldat formájában kerül forgalomba.
          </li>
        </ul>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "AltalanosOlt"
}
</script>

<style lang="scss" scoped>
@import "../../sass/settings";
.card{
    border-radius: 0;
    border: none;
    padding: 60px 40px;
    .card-body{
        margin: 0;
        padding: 0;
    }
    ul{
        margin: 24px 0 8px;
        list-style: none;
        li{
            margin: 0;
            &::before{
                content: "\2022";
                color: $primary;
                font-weight: bolder;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@media screen and (max-width: 765px){
    main{
        background-color: #fff;
        .main-title{
            margin-left: 20px;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100%;
        &>.card{
            width: calc(100vw - 20px);
            margin: 0;
            padding: 20px;
        }
    }
}
</style>
