import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/home'
import Rolunk from './views/rolunk'
import Szolgaltatasok from './views/szolgaltatasok'
import Oltasok from './views/oltasok'
import Galeria from './views/galeria'
import AltalanosOlt from './views/oltasok/altalanos'
import GorenyOlt from './views/oltasok/goreny'
import KutyaOlt from './views/oltasok/kutya'
import MacskaOlt from './views/oltasok/macska'
import NyulOlt from './views/oltasok/nyul'
import Fereghajtas from './views/fereghajtas'
import Ivar from './views/ivar'
import Fogko from './views/fogko'
import Arlista from './views/arlista'
import Kapcsolat from './views/kapcsolat'
import RendeloGaleria from './views/galeria/rendelo'
import VideokGaleria from './views/galeria/videok'
import notfound from './views/notfound'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/rolunk',
      name: 'rolunk',
      component: Rolunk
    },
    {
      path: '/szolgaltatasok',
      name: 'szolgaltatasok',
      component: Szolgaltatasok
    },
    {
      path: '/oltasok',
      name: 'oltasok',
      component: Oltasok
    },
    {
      path: '/galeria',
      name: 'galeria',
      component: Galeria
    },
    {
      path: '/oltasok/altalanos',
      name: 'altalanos_olt',
      component: AltalanosOlt
    },
    {
      path: '/oltasok/goreny',
      name: 'goreny_olt',
      component: GorenyOlt
    },
    {
      path: '/oltasok/kutya',
      name: 'kutya_olt',
      component: KutyaOlt
    },
    {
      path: '/oltasok/macska',
      name: 'macska_olt',
      component: MacskaOlt
    },
    {
      path: '/oltasok/nyul',
      name: 'nyul_olt',
      component: NyulOlt
    },
    {
      path: '/fereghajtas',
      name: 'fereghajtas',
      component: Fereghajtas
    },
    {
      path: '/ivartalanitas',
      name: 'ivar',
      component: Ivar
    },
    {
      path: '/fogkoeltavolitas',
      name: 'fogko',
      component: Fogko
    },
    {
      path: '/arlista',
      name: 'arlista',
      component: Arlista
    },
    {
      path: '/kapcsolat',
      name: 'kapcsolat',
      component: Kapcsolat
    },
    {
      path: '/galeria/kepek',
      name: 'rendelo',
      component: RendeloGaleria
    },
    {
      path: '/galeria/videok',
      name: 'videok',
      component: VideokGaleria
    },
    {
      path: '*',
      name: 'notfound',
      component: notfound
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
