<template functional>
  <footer>
    <b-container>
      <b-row>
        <b-col>
          <p class="footer-font1">
            Viza-Vet Állatorvosi rendelő és Patika
          </p>
          <p class="footer-font2">
            1138 Budapest, Bodor utca 3/c fsz 8-9.
          </p>
          <p class="footer-font2 highlight">
            <span>Bejelentkezés:</span>
            <a
              href="tel:+3612291662"
              class="d-inline text-decoration-none"
            > 06 1 229-16-62, </a>
            <a
              href="tel:+36303921495"
              class="d-inline text-decoration-none"
            >06 30 392-14-95</a>
          </p>
          <p class="footer-font2">
            <span>email: </span>
            <a
              v-mail-to
              href="#"
              class="d-inline text-decoration-none"
            ><span class="email">@</span></a>
          </p>
        </b-col>
        <b-col>
          <p class="footer-font1">
            Nyitvatartás
          </p>
          <p class="footer-font2">
            H-Cs: 10:00-20:00
          </p>
          <p class="footer-font2">
            P: 10:00-19:00
          </p>
          <p class="footer-font2">
            Szo, Vas: ZÁRVA
          </p>
          <p class="footer-font2">
            Bejelentkezéshez minden esetben foglaljon időpontot!<br>
            Bejelentkezés nélküli vizsgálat vagy beavatkozás esetén időpont nélküli pótdíjat számolunk fel!
          </p>
        </b-col>
        <b-col>
          <p class="footer-font1">
            Kövess minket közösségimédia oldalinkon is!
          </p>
          <a
            href="https://www.facebook.com/vizavet/"
            target="_blank"
            class="footer-font2"
          >Facebook</a>
          <a
            href="https://www.instagram.com/vizavet/"
            target="_blank"
            class="footer-font2"
          >Instagram</a>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>

<style lang="scss" scoped>
  @import '../sass/settings';

  footer {
    background-color: #666;
    padding: 50px 0;

    p {
      padding: 0;
      margin: 0;
    }

    .col:nth-of-type(2) {
      max-width: 250px;
    }

    a {
      display: block;
      color: #fff !important;
      cursor: pointer;
    }
  }

  @include media-breakpoint-down(md) {
    .row {
      flex-direction: column;
      text-align: center;

      .col {
        padding: 20px 0;

        &:not(:first-of-type) {
          border-top: 1px solid #888;
        }

        &:nth-of-type(2) {
          max-width: 100%;
        }
      }
    }
  }

.highlight {
  margin-left: -5px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  display: inline-block;
}
</style>
