<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Ajánlott oltási program vadászgörények számára:
        </p>
        <ul>
          <li class="tajekoztato-text">
            8-10. hét: <span class="tajekoztato-higlight1">Kombinált oltás</span>
          </li>
          <li class="tajekoztato-text">
            10-12. hét: <span class="tajekoztato-higlight1">Kombinált ismétlő</span>
          </li>
          <li class="tajekoztato-text">
            14-16. hét: <span class="tajekoztato-higlight1">Veszettség elleni oltás</span> (Kijáró illetve külföldre utazó vadászgörényeknek!)
          </li>
          <li class="tajekoztato-text">
            6. hónap: <span class="tajekoztato-higlight1">Kombilált ismétlő</span> (Szopornyica, Leptospirosis)
          </li>
          <li class="tajekoztato-text">
            1. év: <span class="tajekoztato-higlight1">Veszettség + kombinált oltás</span> (majd évente)
          </li>
        </ul>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "GorenyOlt"
}
</script>

<style lang="scss" scoped>
@import "../../sass/settings";
.card{
    border-radius: 0;
    border: none;
    padding: 60px 40px;
    .card-body{
        margin: 0;
        padding: 0;
    }
    ul{
        margin: 24px 0 8px;
        list-style: none;
        li{
            margin: 0;
            &::before{
                content: "\2022";
                color: $primary;
                font-weight: bolder;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@media screen and (max-width: 765px){
    main{
        background-color: #fff;
        .main-title{
            margin-left: 20px;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100%;
        &>.card{
            width: calc(100vw - 20px);
            margin: 0;
            padding: 20px;
        }
    }
}
</style>
