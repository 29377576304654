<template>
  <main>
    <b-container>
      <p class="main-title">
        Tájékoztatók
      </p>
      <b-card>
        <p class="tajekoztato-title">
          Féreghajtás
        </p>
        <p class="tajekoztato-text">
          Közegészségügyi és állategészségügyi szempontból is fontos az állatok rendszeres féreghajtása, mert egyes férgek emberben is megtelepedhetnek Különösen nagy jelentőségű ez kisgyermekes családoknál, ahol a gyerekek nem feltétlenül tartják be a higiénés szabályokat, illetve az ő immunrendszerük még kevésbé fejlett. A fiatal állatok többsége fertőzött férgekkel, mivel az anyaállat férgessége esetén a kicsik már a méhen belül, illetve a későbbiekben az anyatejjel fertőződnek. A peték a tápcsatornában kikelnek, majd rövid vándorlás után a bélben válnak kifejlett, szaporodóképes féreggé. A bélfalban megkapaszkodnak, táplálkoznak, és a bélsárban továbbítják utódaikat, féregpeték vagy galandférgek esetében féreg-ízek formájában. Nem minden pete jut el a bélbe, vannak, amelyek betokozódnak, mintegy „elbújnak” az izmokban, egyes belső szervekben és csak betegségek, az ellenálló-képesség csökkenésével, például utazások, gazda-váltás, betegség, vemhesség alkalmával vándorolnak ismét tovább a bél irányába.
        </p>
        <p class="tajekoztato-text">
          <span class="tajekoztato-higlight1">Kölyök állatok féregtelenítése:</span>  Kölyköket már 2 hetes kortól lehet féregteleníteni. Rendelőnkben a féreghajtást 6-8 hetes kortól (az oltások mellett) 2 hetente ismételjük.
        </p>
        <p class="tajekoztato-text">
          <span class="tajekoztato-higlight1">Felnőtt állatok</span> esetében az éves kötelező féreghajtáson kívül egy gondos gazdának érdemes 3 havonta féreghajtani az állatot. Javasoljuk, hogy kisgyermekes családokban erre fektessenek különösen nagy hangsúlyt.
        </p>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
    name: "AltalanosOlt"
}
</script>

<style lang="scss" scoped>
@import "../sass/settings";
.card{
    border-radius: 0;
    border: none;
    padding: 60px 40px;
    .card-body{
        margin: 0;
        padding: 0;
    }
    ul{
        margin: 24px 0 8px;
        list-style: none;
        li{
            margin: 0;
            &::before{
                content: "\2022";
                color: $primary;
                font-weight: bolder;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@media screen and (max-width: 765px){
    main{
        background-color: #fff;
        .main-title{
            margin-left: 20px;
        }
    }
    .container{
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100%;
        &>.card{
            width: calc(100vw - 20px);
            margin: 0;
            padding: 20px;
        }
    }
}
</style>
