<template>
  <main>
    <b-container>
      <p class="main-title">
        Oltások
      </p>
      <b-row>
        <b-col>
          <router-link :to="{name: 'altalanos_olt'}">
            <b-card
              img-src="../assets/images/oltasok/oltasok_altalanos.jpg"
              img-top
              class="text-center"
            >
              <div class="card-title">
                Általános
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col>
          <router-link :to="{name: 'kutya_olt'}">
            <b-card
              img-src="../assets/images/oltasok/oltasok_kutya.jpg"
              img-top
              class="text-center"
            >
              <div class="card-title">
                Kutya
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col>
          <router-link :to="{name: 'macska_olt'}">
            <b-card
              img-src="../assets/images/oltasok/oltasok_macska.jpg"
              img-top
              class="text-center"
            >
              <div class="card-title">
                Macska
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col>
          <router-link :to="{name: 'nyul_olt'}">
            <b-card
              img-src="../assets/images/oltasok/oltasok_nyul.jpg"
              img-top
              class="text-center"
            >
              <div class="card-title">
                Nyúl
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col>
          <router-link :to="{name: 'goreny_olt'}">
            <b-card
              img-src="../assets/images/oltasok/oltasok_goreny.jpg"
              img-top
              class="text-center"
            >
              <div class="card-title">
                Görény
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
 <script>
 export default {
     name: "Oltasok"
 }
 </script>

 <style lang="scss" scoped>
 @import '../sass/settings';
.row{
    justify-content: center;
    margin: 0;
    .col{
        flex-grow: 0;
        padding-bottom: 20px;
        a:hover{
            text-decoration: none !important;
        }
    }
}
.card{
    border-radius: 0;
    border: none;
    background-color: $primary;
    color: white;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.14);
    width: 180px;
    margin: 0 auto;
    .card-title{
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        margin-bottom: 0;
    }
    img{
        border-radius: 0;
    }
}
@media screen and (max-width: 1400px){
    .row{
        flex-wrap: wrap;
    }
}
</style>
