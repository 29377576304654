<template functional>
  <header>
    <b-container>
      <b-row
        align-v="center"
        align-h="start"
      >
        <b-col class="p-0">
          <router-link :to="{name: 'home'}">
            <img
              src="../assets/images/vizavet_logo.svg"
              alt="logo"
              class="logo"
            >
          </router-link>
        </b-col>
        <b-col class="p-0">
          <div class="box">
            <p class="header-font1">
              Elérhetőségeink:
            </p>
            <p class="header-font2">
              1138 Budapest, Bodor u. 3/c fsz 8-9
            </p>
            <a
              v-mail-to
              href="#"
              class="header-font2 font-weight-normal d-block"
            ><span
              class="email"
            >@</span></a>
            <div class="highlight">
              <p class="header-font2 font-weight-bold">
                Bejelentkezés telefonon:
              </p>
              <a
                href="tel:+3612291662"
                class="header-font2"
              >06 1 229-16-62, </a>
              <a
                href="tel:+36303921495"
                class="header-font2"
              >06 30 392-14-95</a>
            </div>
          </div>
        </b-col>
        <b-col class="p-0">
          <div class="box">
            <p class="header-font1">
              Nyitvatartás:
            </p>
            <p class="header-font2">
              Hétfő-csütörtök: 10:00-20:00
            </p>
            <p class="header-font2">
              Péntek: 10:00-19:00
            </p>
            <p class="header-font2">
              Szombat, vasárnap: Zárva
            </p>
            <p class="header-font2">
              Bejelentkezéshez minden esetben foglaljon időpontot!<br>
              Bejelentkezés nélküli vizsgálat vagy beavatkozás esetén időpont nélküli pótdíjat számolunk fel!
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
  export default {
    name: 'Header'
  }
</script>

<style lang="scss" scoped>
  @import '../sass/settings';

  header {
    background-color: $primary;
    min-height: 80px;
    padding: 10px 0;

    a {
      font-weight: bold !important;

      &:hover {
        text-decoration: none;
        color: white;
      }
    }

    .row {
      min-height: 110px;
      max-width: 1140px;

      .logo {
        max-width: 300px;
        height: 80px;
      }

      .box {
        height: fit-content;
        border-left: 9px solid $separators;
        padding: 0 10px;

        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 95%;

      .logo {
        height: 70px !important;
      }
    }
  }

  @media (max-width: 820px) {
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      text-align: center;
      margin: 20px 0;

      .box {
        border: none !important;
        margin-top: 25px;
      }
    }
  }

  .highlight {
    margin: 5px -5px 0;
    padding: 12px 11px 12px 5px;
    display: inline-block;
  }
</style>

